<template>
  <img :src="icon" style="width: 22px; height: 22px; min-width: 22px" />
</template>

<script setup lang='ts'>
  import { ref } from 'vue';
  import { RouterType } from '@/model';

  const props = defineProps<{
    type: RouterType
  }>()

  const icon = ref('');

  switch(props.type) {
    case 'miri':
      icon.value = '/images/miri-router-icon.svg';
      break;
    default:
      icon.value = '/images/router-icon.svg';
      break;
  }
</script>
