<template>
  <Dialog
    v-model:visible="visible"
    modal
    :closable="true"
    :style="{ width: '50vw' }"
    header="Reduce the cost of your Speedify Plan"
    pt:title:class="white-space-nowrap mt-4 fs-4"
  >
    <div class="container">
      <div class="row">
        <div style="flex: 1 1 0">
          <h5>Save money by removing seats from your team</h5>
          <p>You have {{ seats.total }} seats on your Speedify Team, but only {{ seats.active }} of them are active.</p>
          <p class="mb-0">
            Want to reduce your seats to {{ seats.active }} for your next billing cycle and save
            {{ format(pricing.off) }} dollars?
          </p>
        </div>

        <img src="/images/guy-with-laptop.png" style="flex: 1 1 0; max-width: 40%" />
      </div>

      <h5>Current Plan</h5>
      <div class="d-flex justify-content-between">
        <span>
          {{ humanReadableProductName(subscription.productName) }}
          {{ hyphenate(capitalizeFirstChar(subscriptionPeriod(subscription.productName))) }}
          {{ seats.total }} Seats
        </span>
        <span v-if="pricing" class="fw-bold">{{ pricing!.currencyCode }}{{ format(pricing!.current) }}/{{ pricing!.unit }}</span>
        <Skeleton v-else />
      </div>
      <div class="d-flex justify-content-between mb-2 highlight-bar">
        <span>Remove extra seats</span>
        <span v-if="pricing" class="fw-bold">-{{ pricing!.currencyCode }}{{ format(pricing!.off) }}/{{ pricing!.unit }}</span>
        <Skeleton v-else />
      </div>
      <div class="d-flex justify-content-between mb-2 fw-bold">
        <span>New Total</span>
        <span v-if="pricing">{{ pricing!.currencyCode }}{{ format(pricing!.newTotal) }}/{{ pricing!.unit }}</span>
        <Skeleton v-else />
      </div>
    </div>

    <template #footer>
      <div class="container mb-4">
        <div class="row column-gap-3">
          <Button
            label="Remove Extra Seats"
            class="col btn btn-speedify-blue stm-btn-cancelation-flow-pause-1"
            style="flex: 1 1 0"
            @click="removeInactiveSeats()"
          />
          <Button
            label="No Thanks, Cancel Subscription"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-go-back-1"
            style="flex: 1 1 0"
            @click="showModal('AreYouSure')"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Skeleton from 'primevue/skeleton';

import { subscriptionPeriod, humanReadableProductName, getSeats } from '@/services/subscription';
import { hyphenate, capitalizeFirstChar } from '@/services/string';
import { useUserStore } from '@/store/user';
import { changeQuantity } from '@/services/backend';
import { modal } from '@/symbol';
import { Team } from '@/model';

const route = useRoute();
const referenceId = route.query.referenceId as string;
const { currModal, showModal } = inject(modal)!;
const visible = computed({
  get: () => currModal.value === 'RemoveInactiveSeats',
  set: () => currModal.value = null,
});
// eslint-disable-next-line @typescript-eslint/unbound-method
const format = new Intl.NumberFormat(navigator.languages, { maximumFractionDigits: 2 }).format;

let { subscriptions } = useUserStore();
const subscription = computed(() => subscriptions.find(s => s.referenceId === referenceId)!);

interface Seats {
  // TODO: move to model.ts
  total: number;
  active: number;
  inactive: number;
}
const emit = defineEmits<{
  'tried-remove-inactive-seats': [result: 'success' | 'failure', seats?: Seats, message?: string | Error];
}>();

const removeInactiveSeats = async () => {
  const result = await changeQuantity(referenceId, seats.value.active);
  if (result.error) {
    emit('tried-remove-inactive-seats', 'failure', seats.value, (result as any).response);
  } else {
    emit('tried-remove-inactive-seats', 'success', seats.value);
  }

  showModal(null);
};
const seats = computed(() => getSeats(subscription.value as { team: Team, quantity: number }));
const pricing = computed<{
  currencyCode: string;
  current: number;
  off: number;
  newTotal: number;
  unit: string;
}>(() => {
  const sub = subscription.value;
  const invoice = subscription.value.invoices.toSorted((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : 0))[0]!;
  const current = invoice.total * seats.value.total;
  const off = invoice.total * seats.value.inactive;
  const newTotal = current - off;
  const unit = {
    'monthly ': 'mo',
    'yearly ': 'yr',
    '3 years ': '3yr', // 3 year subscriptions won't see this modal
    '': '',
  }[subscriptionPeriod(sub.productName)];
  return { currencyCode: invoice.currencyCode, current, off, newTotal, unit };
});
</script>

<style scoped>
.highlight-bar {
  background: #00f4ae33;
  border-radius: 10px;
  padding: 0 8px 0 8px;
  margin: 0 -8px 0 -8px;
}
</style>
