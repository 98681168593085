<template>
  <Dialog
    v-model:visible="visible"
    modal
    :closable="false"
    :draggable="false"
    :style="{ width: '50vw' }"
    header="Pause your Speedify Subscription for 1 month"
    pt:title:class="fw-bold white-space-nowrap mx-auto mt-4 fs-4"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="d-flex row" style="flex: 1 1 0">
          <span class="fw-bold">Need to take a break?</span>
          <p class="mb-0">Pause your Speedify Subscription for 1 month.</p>
          <p>Your plan will be temporarily deactivated on 02/03/24 and resume billing and full access on 03/03/24.</p>
        </div>

        <img src="/images/guy-surfing-on-mobile-with-speedify.png" style="flex: 1 1 0" />
      </div>
    </div>

    <template #footer>
      <div class="container mb-4">
        <div class="row column-gap-3">
          <Button
            label="Nevermind, Go Back"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-go-back-1"
            style="flex: 1 1 0"
            @click="showModal(null)"
          />

          <Button
            label="Pause Subscription"
            class="col btn btn-speedify-blue stm-btn-cancelation-flow-pause-1"
            style="flex: 1 1 0"
            @click="pause()"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import { pauseSubscriptionByReferenceId } from '@/services/backend';
import { modal } from '@/symbol';

const route = useRoute();
const referenceId = route.query.referenceId as string;
const { currModal, showModal } = inject(modal)!;
const visible = computed(() => currModal.value === 'Pause');

const pause = async () => {
  await pauseSubscriptionByReferenceId(referenceId);
};
</script>
