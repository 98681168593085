<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="p-3 mb-4 border rounded-3">
    <div class="col-lg-6 w-100">
      <div v-if="state.loadingDediServers" role="status">
        <span class="visually-hidden">Loading...</span>
        <Skeleton class="mb-4"></Skeleton>
        <Skeleton class="mb-4"></Skeleton>
        <Skeleton class="mb-4"></Skeleton>
        <Skeleton class="mb-4"></Skeleton>
      </div>
      <div v-else-if="state.errorLoadingServers" class="alert alert-danger">
        Error loading analytics for your {{ numServers }} servers:
        <br>
        {{ state.errorLoadingServersMsg }}
      </div>
      <DataTable
        v-else
        stripedRows
        :value="serversData"
        responsiveLayout="scroll"
        class="p-datatable-sm"
        :paginator="state.tablePaginationEnabled"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 15, 20,50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      >
        <Column field="longName" header="Name">
          <template #body="slotProps" >
            <div class="d-flex gap-2">
              <img class="server-flag" :src="getFlagSvgPath(slotProps.data.country)" @error="onFlagError"/>
              <div>
                {{ slotProps.data.longName }}
                <br>
                <span v-if="!slotProps.data.pending" class="text-muted">
                  {{ formatPublicIP(slotProps.data.publicIP) }}
                </span>
                <span v-else class="text-muted">
                  We are working on getting your server up and running
                </span>
              </div>
            </div>
          </template>
        </Column>
        <Column field="currentState" header="Status">
          <template #body="slotProps">
            <span v-if="slotProps.data.currentState === 'ONLINE'" class="text-success">
              {{ slotProps.data.currentState }}
            </span>
            <span v-else-if="slotProps.data.currentState === 'PENDING'" class="text-info">
              PENDING
            </span>
            <span v-else class="mb-0">{{ slotProps.data.currentState }}</span>
          </template>
        </Column>
        <Column field="dc" header="Datacenter">
          <template #body="slotProps">
            {{ slotProps.data.pending ? '-' : upperCaseCity(slotProps.data.dc) }}
            <br>
            <span v-if="slotProps.data.city" class="text-muted">{{ upperCaseCity(slotProps.data.city) }}</span>
          </template>
        </Column>
        <Column field="torrent" header="Torrent">
          <template #body="slotProps">
            {{ slotProps.data.pending ? '-' : slotProps.data.torrent[0].toUpperCase() + slotProps.data.torrent.slice(1) }}
          </template>
        </Column>
        <Column field="connectedUsers" header="Users Now">
          <template #body="slotProps">
            {{ slotProps.data.pending ? '-' : slotProps.data.connectedUsers }}
          </template>
        </Column>
        <Column field="unifiedMetric" header="Utilization">
          <template #body="slotProps">
            <ProgressBar
              v-if="slotProps.data.unifiedMetric"
              :id="slotProps.data.id + '-progress-bar'"
              v-tooltip="`${Math.round(slotProps.data.unifiedMetric * 100)}%`"
              style="height: 7px"
              :value="slotProps.data.unifiedMetric * 100"
              :showValue="false"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onBeforeMount, computed } from 'vue';
import { Subscription } from '@/model';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { getTeamsServersInfo, getPrivateIndividualServersInfo } from '@/services/backend';
import ProgressBar from 'primevue/progressbar';
import Skeleton from 'primevue/skeleton';
import blankSpace from '@/assets/images/solid-white.png'
import { useUserStore } from '@/store/user';

const props = defineProps<{ subscription: Subscription }>();

const userStore = useUserStore();

const numServers = computed(() => {
  if ((props.subscription.type === 'teams' || props.subscription.type.match(/fam/)) && props.subscription?.team!.teamServers.length > 0) {
    return props.subscription.team!.teamServers.length;
  }

  if (props.subscription.type === 'individual') {
    return userStore.servers?.filter(s => s.teamId === -1).length;
  }

  return 0;
});

const state = reactive({
  loadingDediServers: false,
  errorLoadingServers: false,
  errorLoadingServersMsg: '',
  tablePaginationEnabled: numServers.value > 10,
});

const serversData = ref();

const formatPublicIP = (publicIP: Array<string>) => `${publicIP[0]}`;

const upperCaseCity = (word: string) => {
  // cities are lowercased and may contain hyphens or spaces
  const spacesArr = word.split(' ');
  const upperCased = spacesArr.map(w => {
    return w.split('-').map(e => e[0]!.toUpperCase() + e.slice(1)).join('-');
  }).join(' ');
  return upperCased;
};

const getFlagSvgPath = (country: string) => country === 'PENDING' ? '/images/DEDICATED-SERVER.png' : `/images/flags/${country.slice(-2)}.svg`;

const onFlagError = (e: Event) => {
  const img = e.target as HTMLImageElement;
  img.src = blankSpace;
};

onBeforeMount(async () => {
  let result = [];

  if ((props.subscription.type === 'teams' || props.subscription.type.match(/fam/)) && props.subscription?.team!.teamServers.length > 0) {
    try {
      state.loadingDediServers = true;
      result = await getTeamsServersInfo(props.subscription?.team!.teamId.toString());
      if (result.error) {
        throw result.message;
      }
      state.loadingDediServers = false;
    } catch (err) {
      state.loadingDediServers = false;
      state.errorLoadingServers = true;
      state.errorLoadingServersMsg = err as string;
    }
  }

  if (props.subscription.type === 'individual') {
    try {
      state.loadingDediServers = true;
      result = await getPrivateIndividualServersInfo();
      if (result.error) {
        throw result.message;
      }
      state.loadingDediServers = false;
    } catch (err) {
      state.loadingDediServers = false;
      state.errorLoadingServers = true;
      state.errorLoadingServersMsg = err as string;
    }
  }

  if (!state.errorLoadingServers) {
    const pendingDedicatedServers = userStore.dedicatedServerQuantity - result.length;
    if (pendingDedicatedServers > 0) {
      serversData.value = [
        ...Array(pendingDedicatedServers).fill({
          longName: 'Pending New Server',
          currentState: 'PENDING',
          country: 'PENDING',
          pending: true,
        }),
        ...result,
      ];
    } else {
      serversData.value = result;
    }
  }
});
</script>
